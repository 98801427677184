import axios from 'axios';
import authHeader from './auth-header';

const ORG_USERS_API_URL = 'https://broxtech-backend-ced04e937784.herokuapp.com/organisations';
const ACCOUNT_RECOVERY_API_URL = 'https://broxtech-backend-ced04e937784.herokuapp.com/accountRecovery'

class UserService {
  getUsers() {
    return axios.get(ORG_USERS_API_URL + '/hpc', { headers: authHeader() });
  }
  sendEmail(email){
    var formData = new FormData();
    formData.append('email', email);
    return axios.post(ACCOUNT_RECOVERY_API_URL, formData, { headers: authHeader()})
  }
}
export default new UserService();
