import axios from 'axios';
import authHeader from './auth-header';

const EVENTS_API_URL = 'https://broxtech-backend-ced04e937784.herokuapp.com/events/hpc';
const IMAGES_API_URL = 'https://broxtech-backend-ced04e937784.herokuapp.com/events/images/hpc';
const IMAGE_API_URL = 'https://broxtech-backend-ced04e937784.herokuapp.com/events/image/hpc'

class EventService {
  async delete(eventModel){
    await axios.delete(EVENTS_API_URL + '/delete/' + eventModel, { headers: authHeader() });
  }
  getEvents() {
    return axios.get(EVENTS_API_URL);
  }
  getEvent(event){
    return axios.get(EVENTS_API_URL + '/' + event);
  }
  getEventImages(event){
    return axios.get(IMAGES_API_URL + '/' + event);
  }
  getNextEvent(){
    return axios.get(EVENTS_API_URL + '/next-event');
  }
  async createEvent(eventModel){
    let website = 'https://www.hillingtonparkchurch.co.uk/signup'
    var formData = new FormData()
    formData.append('name',eventModel.name)
    formData.append('description', eventModel.description)
    formData.append('address', eventModel.address)
    formData.append('postcode', eventModel.postcode)
    formData.append('eventdate', eventModel.date)
    formData.append('website', website)
    formData.append('organisation', "hpc")
    formData.append('file', eventModel.file);
    return await axios.post(EVENTS_API_URL, formData, { headers: authHeader()})
  }
  async uploadEventImage(image, eventId){
    var formData = new FormData()
    formData.append('file', image)
    return await axios.post(IMAGE_API_URL + '/' + eventId, formData, { headers: authHeader()})  
  }
  async updateEvent(eventModel){
    return await axios.put(EVENTS_API_URL, {
      name: eventModel.name,
      description: eventModel.description? eventModel.description: "",
      address: eventModel.address? eventModel.address: ""
    })
  }
  async generateQRCode(event){
    return await axios.post(EVENTS_API_URL + '/qr/' + event.name + "hpc")
  }
}
export default new EventService();
