import { createWebHistory, createRouter } from "vue-router";
import Home from "../views/Public/HomeView.vue";
import Login from "../views/Public/LoginView.vue";
import About from "../views/Public/AboutView.vue";
import Register from "../views/Public/RegisterView.vue";
import axios from 'axios';
import authHeader from '../services/auth-header';
import store from '@/store/index.js';


const API_URL = 'https://broxtech-backend-ced04e937784.herokuapp.com/auth';
// lazy-loaded
const Profile = () => import("../views/Admin/ProfileView.vue")
const AdminRegisterUser = () => import("../views/Admin/AdminRegisterUserView.vue")
const DocumentUpload = () => import("../views/Admin/DocumentUpload.vue")
const WhatWeBelieve = () => import("../views/Public/WhatWeBelieveView.vue")
const Reverend = () => import("../views/Public/ReverendView.vue")
const ChurchContacts = () => import("../views/Public/ChurchContactsView.vue")
const MobileMenu = () => import("../views/Public/MobileMenuView.vue")
const SignUp = () => import("../views/Public/EventSignupView.vue")
const CreateEvent = () => import("../views/Admin/CreateEventView.vue")
const ChurchEvents = () => import("../views/Admin/ChurchEvents.vue")
const Events = () => import("../views/Public/ChurchEventsPublic.vue")
const PastEvent = () => import("../components/Events/PastEvent.vue")
const TodaysEvent = () => import("../components/Events/TodaysEvent.vue")
const FutureEvent = () => import("../components/Events/FutureEvent.vue")
const PastEventPublic = () => import("../components/Events/PastEventPublic.vue")
const TodaysEventPublic = () => import("../components/Events/TodaysEventPublic.vue")
const FutureEventPublic = () => import("../components/Events/FutureEventPublic.vue")

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: '/about',
    component: About
  },
  {
    path: '/what-we-believe',
    component: WhatWeBelieve
  },
  {
    path: '/our-minister',
    component: Reverend
  },
  {
    path: '/mobile-menu',
    component: MobileMenu
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/profile",
    name: "profile",
    // lazy-loaded
    component: Profile,
  },
  {
    path: "/add-member",
    name: "add-member",
    // lazy-loaded
    component: AdminRegisterUser,
  },
  {
    path: "/church-contacts",
    name: "church-contacts",
    // lazy-loaded
    component: ChurchContacts,
  },
  {
    path: "/past-event/:id?",
    component: PastEvent,
    props:true
  },
  {
    path: "/todays-event/:id?",
    component: TodaysEvent,
    props:true
  },
  {
    path: "/future-event/:id?",
    component: FutureEvent,
    props:true
  },
  {
    path: "/previous-event/:id?",
    component: PastEventPublic,
    props:true
  },
  {
    path: "/todays/:id?",
    component: TodaysEventPublic,
    props:true
  },
  {
    path: "/upcoming-event/:id?",
    component: FutureEventPublic,
    props:true
  },
  {
    path:"/signup/:eventId?",
    component: SignUp,
    props:true
  },
  {
    path: "/documents",
    component: DocumentUpload,
  }, 
  {
    path: "/create-event",
    component: CreateEvent
  }, 
  {
    path: "/church-events",
    component: ChurchEvents
  }, 
  {
    path: "/events",
    component: Events
  }
];

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
  routes,
});

router.beforeEach(async (to, from, next) => {
  const publicPages = [
    '/home',
    '/login', 
    '/register', 
    '/about', 
    '/what-we-believe', 
    '/our-minister', 
    '/church-contacts', 
    '/mobile-menu', 
    '/events',
    '/previous-event',
    '/todays',
    '/upcoming-event'];
    const authRequired = 
      !publicPages.includes(to.path) && (
        !to.path.startsWith('/verify') &&  
        !to.path.startsWith('/signup') &&
        !to.path.startsWith('/upcoming-event')&&
        !to.path.startsWith('/todays-event')&&
        !to.path.startsWith('/previous-event'));
    try{
        if (authRequired) {
          let resp = await axios.get(API_URL, { headers: authHeader() })
          if (resp.status==200){
            if(resp.data.loggedin==='false'){
              store.commit('auth/logout')
              next('/home');
            }
          }
        }
        next();
    }
    catch(err){
      console.log('an error occurred')
      this.$store.dispatch('auth/logout')
      next('/home');
    }
  });

export default router;
