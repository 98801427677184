import { createStore } from "vuex";
import { auth } from "./auth.module";
import { user } from "./user.module"
import { role } from './role.module';
import { documents } from './documents.module'
import { resizer } from "./resize.module";
import { events } from "./events.module"
import { participants} from "./participants.module"

const store = createStore({
  modules: {
    auth,
    user,
    role,
    documents,
    resizer,
    events,
    participants
  },
});
export default store;
