import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'https://broxtech-backend-ced04e937784.herokuapp.com/auth';

class AuthService {
  async login(user) {
    const response = await axios
      .put(API_URL, {
        email: user.username,
        password: user.password,
        organisation_uri: 'hpc'
      });
    if (response.data.access_token) {
      localStorage.setItem('user', JSON.stringify(response.data));
    }
    return response.data;
  }
  async isLoggedIn(){
    try{
      let user = await axios.get(API_URL, { headers: authHeader() })
      if (!user.status==200){
        window.location.reload()
        this.$router.push('/login');
        return false
      }
      localStorage.setItem('user', JSON.stringify(user.data));
      return user.data
    }
    catch(error){
      return false
    }

  }
  
  logout() {
    localStorage.removeItem('user');
  }
  register(user) {
    return axios.post(API_URL, {
      first_name: user.first_name,
      second_name: user.second_name,
      email: user.email,
      password: user.password,
      role: user.role
    });
  }
  remove(user){
    let username = user.username;
    return axios.delete(API_URL + '/' + username, {
      username: user.username
    }, { headers: authHeader() })
  }
  async delete(username){
    axios.delete(API_URL + '/delete/' + username, { headers: authHeader() });
  }
}
export default new AuthService();
