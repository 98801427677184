import EventService from '../services/event.service';

const initialState = {
  events: [],
  event: null,
  registered: false,
  image:false,
  eventImages:null
}

export const events = {
  namespaced: true,
  state: initialState,
  actions: {
    async getEvents({ commit }) {
      let allEvents = await EventService.getEvents();
      commit('populateEvents', allEvents.data.events);
    },
    async getEventImages({ commit }, event) {
      let allEvents = await EventService.getEventImages(event);
      commit('populateEventImages', allEvents.data.images);
    },
    async getEvent({commit}, event) { 
      let eventResponse = await EventService.getEvent(event)
      commit('populateEvent',eventResponse.data)
    },
    async getNextEvent({commit}, event) { 
      let eventResponse = await EventService.getNextEvent(event)
      commit('populateEvent',eventResponse.data)
    },
    async createEvent({ commit }, eventModel) {
      await EventService.createEvent(eventModel)
      commit('createEvent', eventModel)
    },
    async editEvent({commit}, eventModel){
      await EventService.editEvent(eventModel)
      commit('updateEvent')
    }
  },
  mutations: {
    imageUploaded(state){
      state.image = true
    },
    populateEvents(state, events) {
      state.events = events
    },
    populateEvent(state, event) {
      state.event = event
    },
    populateEventImages(state, eventImages) {
      state.eventImages = eventImages
    },
    createEvent(state, event) {
      state.event = event
    },
    updateEvent(state, event) {
      state.event = event
    }
  },
}